import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Tilt from "react-parallax-tilt";
import myIcon from '../../Assets/opensea_symbol-512.webp'

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Tilt>
        <Card.Img variant="top" src={props.imgPath} height={400} alt="card-img" />        
      </Tilt>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button variant="primary" href={props.ghLink} target="_blank" disabled={props.openSea}>
          {/* <BsGithub /> &nbsp; */}
          <img src={myIcon} alt="OpenSea link" width={44} height={44} /> &nbsp;
          OpenSea
        </Button>
        {"\n"}
        {"\n"}
        
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
