import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import chatify from "../../Assets/Projects/s_mihou_t_1.png";
import mountain from "../../Assets/Projects/mountain_new.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              openSea={false}
              title="Astronaut"
              description="Travelling alteration impression six all uncommonly. Chamber hearing inhabit joy highest private ask him our believe. Up nature valley do warmly. Entered of cordial do on no hearted. Yet agreed whence and unable limits. Use off him gay abilities concluded immediate allowance."
              ghLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/69474398453236932749138878190616423500920948165547260614946685154679381295105/"
              demoLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/69474398453236932749138878190616423500920948165547260614946685154679381295105/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mountain}
              isBlog={false}
              openSea={true}
              title="Mountain View"
              description="Travelling alteration impression six all uncommonly. Chamber hearing inhabit joy highest private ask him our believe. Up nature valley do warmly. Entered of cordial do on no hearted. Yet agreed whence and unable limits. Use off him gay abilities concluded immediate allowance."
              ghLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/69474398453236932749138878190616423500920948165547260614946685154679381295105/"
              demoLink="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/69474398453236932749138878190616423500920948165547260614946685154679381295105/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
